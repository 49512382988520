import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            Footer Design ©{new Date().getFullYear()} Created by Geeks Network
        </div>
    );
};

export default Footer;