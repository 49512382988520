import React from 'react';
import "../../css/404/404.css"

const PageNotFound = () => {
    return (
        <div className="container_404">
            <p className="title_404"> 404 PAGE NOT FOUND</p>
        </div>
    );
};

export default PageNotFound;